


























































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import DetailsRetirementFundModalViewModel
  from '@/vue-app/view-models/components/goals-dashboard/details-goals/details-retirement-fund-modal-view-model';

import {
  InvestmentProductFundTypesEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';

@Component({
  name: 'DetailsRetirementFundModal',
  components: {
    LinkStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-status-moderate/LinkStatusModerateDialog.vue'),
    ContractingStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/contracting-status-moderate/ContractingStatusModerateDialog.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
    CustomProgressBar: () => import('@/vue-app/components/goals-dashboard/details-goals/CustomProgressBar.vue'),
  },
})
export default class DetailsRetirementFundModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('investorGoalId', { type: String, required: true })
  investor_goal_id!: string;

  @PropSync('strategyOption', { type: String, required: true })
  strategy_option!: string;

  @PropSync('investmentProductFundTypes', { type: Object, required: true })
  investment_product_fund_types!: InvestmentProductFundTypesEntity;

  details_retirement_fund_view_model = Vue.observable(
    new DetailsRetirementFundModalViewModel(this),
  );

  created() {
    this.details_retirement_fund_view_model.initialize(
      this.investor_goal_id, this.strategy_option,
    );
  }

  closeModal() {
    this.synced_is_open = false;
  }

  upgradeToModerate() {
    this.synced_is_open = false;
    this.details_retirement_fund_view_model.upgradeToModerate();
  }

  modifyStrategy() {
    this.synced_is_open = false;
    this.details_retirement_fund_view_model.modifyStrategy();
  }

  showTransferStatusModerate() {
    this.synced_is_open = false;
    this.details_retirement_fund_view_model.showTransferStatusModerate();
  }
}
